import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import {
  brandColours,
  standardColours,
  fontSize,
  fontWeights,
  maxBreakpointQuery,
  minBreakpointQuery,
  visuallyHidden,
  zIndexLayers,
  labelStyles,
  fullLabelStyles,
  inputStyles,
  textAreaStyles,
  selectWrapStyles,
  selectStyles,
  formButtonStyles,
  headingStyles,
} from '../styles';
import { Button, Heading, Svg } from './ui';
import { translateString } from '../utils';
import { SiteContext } from '../context/SiteContext';
import tickIcon from '../images/tick.svg';

const StyledPopupForm = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${({ isPopupFormOpen }) => (isPopupFormOpen ? 'block' : 'none')};
  z-index: ${zIndexLayers.fifth};
`;

const StyledContainer = styled.div`
  position: fixed;
  top: 50%;
  right: 30px;
  left: 30px;
  margin-right: auto;
  margin-left: auto;
  transform: translate(0, -50%);

  ${minBreakpointQuery.medium`
    width: 780px;
  `}

  &:before {
    content: '';
    background-color: black;
  }
`;

const StyledClose = styled.button`
  background: none;
  border: none;
  position: absolute;
  right: -20px;
  top: -20px;
  padding: 17px 20px;
  background-color: ${brandColours.primary};
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    color: ${brandColours.tertiary};
  }

  &:before {
    content: '✕';
    color: ${standardColours.white};
    ${fontSize(16)};
    font-weight: ${fontWeights.bold};
  }

  span {
    ${visuallyHidden()};
  }
`;

const StyledOuter = styled.div`
  background-color: ${brandColours.secondary};
  padding: 30px;
  border-radius: 20px;
  height: ${({ formSubmitted }) => (formSubmitted ? 'auto' : '95vh')};
  max-height: 840px;
  overflow: auto;

  ${minBreakpointQuery.tiny`
    padding-right: 40px;
    padding-left: 40px;
  `}

  ${minBreakpointQuery.small`
    padding: 40px 60px;
  `}

  ${minBreakpointQuery.medium`
    padding: 50px 80px;
  `}

  ${minBreakpointQuery.large`
    padding: 60px 100px;
  `}
`;

const StyledHeading = styled.h2`
  ${headingStyles(undefined, '40px')};

  ${maxBreakpointQuery.medium`
    margin-top: 20px;
  `}
`;

const StyledText = styled.p`
  margin-top: 10px;
  line-height: 1.5;

  ${minBreakpointQuery.mlarge`
    ${fontSize(20)};
    margin-top: 15px;
  `}
`;

const StyledForm = styled.form`
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;

  ${minBreakpointQuery.medium`
    padding-top: 30px;
  `}

  ${({ hide }) => {
    if (hide) {
      return `
        display: none;
      `;
    }
  }}
`;

const StyledLabel = styled.label`
  ${labelStyles()};
`;

const StyledLabelFull = styled.label`
  ${fullLabelStyles()};
`;

const StyledLabelText = styled.span``;

const StyledInput = styled.input`
  ${inputStyles()};
`;

const StyledTextArea = styled.textarea`
  ${textAreaStyles()};
`;

const StyledSelectWrap = styled.div`
  ${selectWrapStyles()};
`;

const StyledSelect = styled.select`
  ${selectStyles()};
`;

const StyledSubmit = styled(Button)`
  ${formButtonStyles()};
`;

const StyledSubmitOuter = styled.div`
  background-color: #e7f2ff;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const StyledSubmitInner = styled.div`
  text-align: center;
`;

const StyledTick = styled(Svg)`
  height: 80px;
  width: 80px;
`;

const StyledSubmitHeading = styled(Heading)`
  margin: 30px 0 15px;
`;

const StyledSubmitText = styled.p`
  ${fontSize(20)};

  ${minBreakpointQuery.large`
    ${fontSize(26)};
  `}
`;

const PopupForm = ({ locale }) => {
  const { isPopupFormOpen, togglePopupFormOpen, popupFormType } =
    useContext(SiteContext);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    const myForm = document.getElementById(e.srcElement.id);
    const formData = new FormData(myForm);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setFormSubmitted(true);
      })
      .catch(error => alert(error));
  };

  return (
    <StyledPopupForm isPopupFormOpen={isPopupFormOpen}>
      <StyledContainer>
        <StyledClose
          onClick={() => {
            setFormSubmitted(false);
            togglePopupFormOpen();
          }}
        >
          <span>Close</span>
        </StyledClose>
        <StyledOuter formSubmitted={formSubmitted}>
          {!formSubmitted ? (
            <>
              <StyledHeading maxFontSize={40}>
                {translateString(`Request a ${popupFormType}`, locale)}
              </StyledHeading>
              <StyledText>
                {translateString(
                  'Complete the following form and we’ll be in touch',
                  locale
                )}
              </StyledText>
              <StyledForm
                id="callback-form"
                name="callback-form"
                method="POST"
                data-netlify="true"
                hide={popupFormType !== 'callback'}
                onSubmit={e => handleSubmit(e)}
              >
                <input type="hidden" name="form-name" value="callback-form" />
                <StyledLabelFull>
                  <StyledLabelText>
                    {translateString('Your name', locale)}*
                  </StyledLabelText>
                  <StyledInput type="text" name="name" required />
                </StyledLabelFull>
                <StyledLabelFull>
                  <StyledLabelText>
                    {translateString('Company name', locale)}
                  </StyledLabelText>
                  <StyledInput type="text" name="company-name" />
                </StyledLabelFull>
                <StyledLabel>
                  <StyledLabelText>
                    {translateString('Phone number', locale)}*
                  </StyledLabelText>
                  <StyledInput type="tel" name="phone" required />
                </StyledLabel>
                <StyledLabel>
                  <StyledLabelText>
                    {translateString('E-mail address', locale)}*
                  </StyledLabelText>
                  <StyledInput type="email" name="email" required />
                </StyledLabel>
                <StyledLabelFull>
                  <StyledLabelText>
                    {translateString('I’d like to talk to you about', locale)}
                  </StyledLabelText>
                  <StyledSelectWrap>
                    <StyledSelect type="select" name="topic" defaultValue="">
                      <option value="">
                        {translateString('Please Select', locale)}
                      </option>
                      <option value="General">
                        {translateString('General', locale)}
                      </option>
                      <option value="Info">
                        {translateString('Info', locale)}
                      </option>
                      <option value="Other">
                        {translateString('Other', locale)}
                      </option>
                    </StyledSelect>
                  </StyledSelectWrap>
                </StyledLabelFull>
                <StyledSubmit type="submit">
                  {translateString(`Request a callback`, locale)}
                </StyledSubmit>
              </StyledForm>
              <StyledForm
                id="quote-form"
                name="quote-form"
                method="POST"
                data-netlify="true"
                hide={popupFormType !== 'quote'}
                onSubmit={e => handleSubmit(e)}
              >
                <input type="hidden" name="form-name" value="quote-form" />
                <StyledLabelFull>
                  <StyledLabelText>
                    {translateString('Your name', locale)}*
                  </StyledLabelText>
                  <StyledInput type="text" name="name" required />
                </StyledLabelFull>
                <StyledLabelFull>
                  <StyledLabelText>
                    {translateString('Company name', locale)}
                  </StyledLabelText>
                  <StyledInput type="text" name="company-name" />
                </StyledLabelFull>
                <StyledLabel>
                  <StyledLabelText>
                    {translateString('Phone number', locale)}*
                  </StyledLabelText>
                  <StyledInput type="tel" name="phone" required />
                </StyledLabel>
                <StyledLabel>
                  <StyledLabelText>
                    {translateString('E-mail address', locale)}*
                  </StyledLabelText>
                  <StyledInput type="email" name="email" required />
                </StyledLabel>
                <StyledLabelFull>
                  <StyledLabelText>
                    {translateString('More Details', locale)}
                  </StyledLabelText>
                  <StyledTextArea name="more-details" />
                </StyledLabelFull>
                <StyledSubmit type="submit">
                  {translateString(`Request a quote`, locale)}
                </StyledSubmit>
              </StyledForm>
            </>
          ) : (
            <StyledSubmitOuter>
              <StyledSubmitInner>
                <StyledTick image={tickIcon} />
                <StyledSubmitHeading>
                  {translateString('Your message has been sent', locale)}
                </StyledSubmitHeading>
                <StyledSubmitText>
                  {translateString('Thank you, we’ll be in touch', locale)}
                </StyledSubmitText>
              </StyledSubmitInner>
            </StyledSubmitOuter>
          )}
        </StyledOuter>
      </StyledContainer>
    </StyledPopupForm>
  );
};

export default PopupForm;
